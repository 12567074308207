.alert {
    padding: 0.8rem;
    margin: 1rem 0;
    opacity: 0.9;
    background: var(--light-color);
    color: #333;
    font-family: Arial;
    font-size: $m-size;
}


.alert-primary {
    background: var(--primary-color);
    color: #fff;
}
  
.alert-light {
    background: var(--light-color);
    color: #333;
}
  
.alert-dark {
    background: var(--dark-color);
    color: #fff;
}
  
.alert-danger {
    background: var(--danger-color);
    border: 1px solid #ff0000;
    background-color: #fff6f3;
    color: $black;
}
  
.alert-success {
    background: var(--success-color);
    color: #fff;
}
  
.alert-white {
    background: #fff;
    color: #333;
    border: #ccc solid 1px;
}
