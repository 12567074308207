.home-container {
    display: flex;
}

.home-welcome-image {
    
}

.legacy-left-banner {
    float: left;
}

.legacy-home-container {
    text-align: center;
}

.legacy-title {
    color: $grey;
    font-size: 3em;
    font-weight: bold;
}

.legacy-title img {
    max-width: 95%;
}

.adsbygoogle {
    display: block;
}

@media (min-width: $desktop-breakpoint) {

    .mobile {
        display: none;
    }
}

@media (max-width: $desktop-breakpoint) {

    .desktop {
        display: none;
    }
}